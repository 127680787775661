<template>
	<div class="proposal" @click="gotoDetail(data.proposals_snapshotId)">
		<div class="proposal-title">
			<div class="title">
				{{ data.proposals_title }}
			</div>
			<div>
				<button @click.stop="toggleWatchlist(data)">
					<img
						:src="'@/assets/img/icon/bookmarked.svg'"
						alt=""
						v-if="
							watchList
								.map((item) => item.proposals_snapshotId)
								.indexOf(data.proposals_snapshotId) !== -1
						"
					/>
					<img :src="'@/assets/img/icon/bookmarks.svg'" alt="" v-else />
				</button>
			</div>
		</div>
		<div class="proposal-content">
			<div class="proposal-detail">
				<div style="color: #454348">{{ voteDetail.voteName }}</div>
				<div style="color: #454348">
					{{ voteDetail.percent }}
				</div>
			</div>

			<div class="progress-bar">
				<div
					v-bind:style="
						getProgress(
							voteDetail.percent,
							voteDetail.voteName
						)
					"
				></div>
			</div>
			<div class="vote-detail">
				{{ voteDetail.vp ? voteDetail.vp : 0 }} VP ({{
					voteDetail.votes > 1
						? `${voteDetail.votes} votes`
						: `${voteDetail.votes} vote`
				}}
				)
			</div>
		</div>

		<div class="h-bar"></div>

		<div class="proposal-info">
			<span class="proposal-status" :class="data.proposals_status">
				{{ formatStatus(data.proposals_status) }}
			</span>
			<span class="proposal-category">
				{{ formatCategory(data.proposals_type) }}
			</span>
		</div>
	</div>
</template>

<script>
import { getVotes, getScores } from '@/services/proposals.js'
import { string2Balance } from '@/features/Common.js'
export default {
	name: 'ActivityItem',
	props: {
		data: Object,
		watchList: Array,
		curTab: String,
		currentPage: {
			type: Number,
			default: 1,
		},
		status: String,
	},
	data() {
		return {
			result: {
				voteYes: {
					percent: '0%',
					votes: 0,
					vp: 0,
				},
				voteNo: {
					percent: '0%',
					votes: 0,
					vp: 0,
				},
			},
			voteDetail: {
				voteName: 'Disagree',
				votes: 0,
				vp: 0,
				percent: '0%',
			},
		}
	},
	created() {
		this.getVotes()
	},
	watch: {
		data(val) {
			this.getVotes()
		},
	},
	methods: {
		getProgress: function (width, vote) {
			return {
				width: width,
				background: vote === 'Agree' ? '#34D399' : '#F87171',
				height: '10px',
				'border-radius': '3px',
			}
		},
		toggleWatchlist(data) {
			this.$emit('update-watchlist', data)
		},
		formatStatus(status) {
			switch (status) {
				case 'inprogress':
					return 'Voting in Progress'
				case 'ended':
					return 'End of Voting'
				case 'proceeding':
					return 'Proceeding'
				case 'complete':
					return 'Complete'
				case 'rejected':
					return 'Rejected'
				default:
					break
			}
		},
		formatCategory(type) {
			switch (type) {
				case 'map-building':
					return 'Map & Building'
				case 'on-doll':
					return 'On-Doll'
				case 'item':
					return 'Item'
				case 'business':
					return 'Business'
				case 'game':
					return 'Game'
				case 'system':
					return 'System'
				case 'name-ban':
					return 'Name Ban'
				case 'etc':
					return 'ETC.'
				default:
					break
			}
		},
		async getVotes() {
			await getVotes(this.data).then(async(res) => {
				this.seeVotes = res.data.votes
				let yesVP = 0
				let noVP = 0
				let nYesVote = 0
				let nNoVote = 0
				if (res.data.votes) {
					res.data.votes.map((item) => {
						if (item.choice === 1) {
							yesVP += item.vp
							nYesVote += 1
						} else {
							noVP += item.vp
							nNoVote += 1
						}
					})
				}
				this.result.voteYes.vp = string2Balance(yesVP, 0)
				this.result.voteNo.vp = string2Balance(noVP, 0)
				this.result.voteYes.votes = nYesVote
				this.result.voteNo.votes = nNoVote
				let yesPercent = (yesVP / (yesVP + noVP)) * 100
				let noPercent = (noVP / (yesVP + noVP)) * 100
				this.result.voteYes.percent =
					yesPercent > 0 ? yesPercent.toFixed(2) + '%' : '0%'
				this.result.voteNo.percent =
					noPercent > 0 ? noPercent.toFixed(2) + '%' : '0%'

				if(yesVP + noVP == 0) {
					this.voteDetail = {
						...this.result.voteYes,
						voteName: 'Agree'
					}
				}
				else {
					this.voteDetail =
						yesPercent > noPercent
							? {
									...this.result.voteYes,
									voteName:
										yesPercent > noPercent
											? 'Agree'
											: 'Disagree',
							}
							: {
									...this.result.voteNo,
									voteName:
										yesPercent > noPercent
											? 'Agree'
											: 'Disagree',
							}
				}
			})
		},
		gotoDetail(id) {
			this.$router.push({
				name: 'Dao-Detail',
				params: { itemId: id },
				query: {
					backRoute: 'Activity',
					curTab: this.curTab,
					page: this.currentPage,
					status: this.status,
				},
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.proposal {
	padding: gREm(24);
	&:hover {
		img {
			display: inline !important;
		}
	}
	.proposal-title {
		@include FLEX(space-between, flex-start);
		.title {
			font-weight: 700;
			font-size: 16px;
			line-height: 1.5;
			color: #17141a;
			height: auto;
			overflow: auto;
			cursor: pointer;
			min-height: 48px;
			text-overflow: ellipsis;
			overflow: hidden;
			word-break: break-word;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}
	}
	.proposal-content {
		.progress-bar {
			display: block;
			background: #efeeef;
			border-radius: 3px;
		}
		.proposal-detail {
			@include FLEX(space-between, flex-start);
			margin-top: gREm(16);
			margin-bottom: gREm(8);
			font-weight: 400;
			font-size: 14px;
			line-height: 150%;
		}
		.vote-detail {
			margin-top: gREm(8);
			color: #454348;
			font-weight: 400;
			font-size: 14px;
			line-height: 150%;
		}
	}
	.proposal-info {
		margin-top: 15px;
		display: flex;
		gap: 10px;
		.proposal-status {
			display: inline-block;
			border-radius: 4px;
			padding: 6px 8px;
			font-size: 14px;
			font-weight: 500;
			white-space: nowrap;
			&.inprogress {
				color: #3b82f6;
				background: #e2ecfe;
				border: 1px solid #e2ecfe;
			}
			&.ended {
				color: #34d399;
				background: #e1f9f0;
				border: 1px solid #e1f9f0;
			}
			&.proceeding {
				color: #6c38ef;
				background: #eef2ff;
				border: 1px solid #eef2ff;
			}
			&.complete {
				color: #fcd34d;
				background: #fff9e5;
				border: 1px solid #fff9e5;
			}
			&.rejected {
				color: #f87171;
				background: #feeaea;
				border: 1px solid #feeaea;
			}
		}
		.proposal-category {
			display: inline-block;
			border-radius: 4px;
			border: 1px solid #747276;
			color: #747276;
			padding: 6px 8px;
			font-size: 14px;
			font-weight: 500;
			white-space: nowrap;
		}
	}
	.h-bar {
		margin-top: gREm(16);
		width: 100%;
		height: 1px;
		background-color: #dedede;
		&[active='on'] {
			height: gREm(2);
			background-color: #6c38ef;
		}
	}
}
</style>
