<template>
	<div class="title-box">
		<HeaderTab />
	</div>
	<div class="activity">
		<div class="activity-wrap">
			<Activity />
		</div>
	</div>
	<Foot :theme="'white'" />
</template>

<script>
import Foot from '@/components/FOOT.vue'
import Activity from '@/components/Activity/Activity.vue'
import HeaderTab from '@/components/HeaderTab.vue'

export default {
	name: 'ActivityPage',
	components: {
		Foot,
		Activity,
		HeaderTab
	},
	data() {
		return {}
	},
	methods: {},
}
</script>

<style lang="scss" scoped>
.title-box {
	@include FLEXV(flex-start, center);
	width: 100%;
	height: gREm(204);
	background-image: url('../assets/img/dao/header-bg.png');
	background-repeat: no-repeat;
	background-size: cover;
	.title-wrap {
		@include FLEXV(center, center);
		width: auto;
		height: gREm(95);
		margin-top: gREm(215);
		.title {
			height: auto;
			width: gREm(322);
			@include Set-Font($AppFont, gREm(34), gREm(52), #ffffff, 600);
			text-align: center;
		}
		.sub-title {
			height: auto;
			width: auto;
			@include Set-Font($AppFont, gREm(34), gREm(52), #ffffff);
			text-align: center;
		}
	}
}

.activity {
	font-family: 'Montserrat';
	background: white;
	min-height: calc(100vh - 144px - 250px);
	.activity-wrap {
		width: 100%;
		max-width: 1180px;
		height: auto;
		margin: 0 auto;
		padding: 30px 20px 20px;
	}
}
@include media-max($media_small) {
	// 768
	.activity-wrap {
		padding: 20px !important;
	}
}
</style>
