<template>
	<div class="activity">
		<div class="header">
			<div class="tab-res">
				<div class="tab-list">
					<div
						@click="changeTab('Proposals')"
						class="tab-item"
						:class="{ active: curTab === 'Proposals' }"
					>
						My Proposals
					</div>
					<div
						@click="changeTab('Watchlist')"
						class="tab-item"
						:class="{ active: curTab === 'Watchlist' }"
					>
						Watchlist
					</div>
					<div
						@click="changeTab('MyVotes')"
						class="tab-item"
						:class="{ active: curTab === 'MyVotes' }"
					>
						My Votes
					</div>
				</div>
			</div>
			<div class="status-box">
				<button
					class="status"
					@click="isShowDropdownStatus = !isShowDropdownStatus"
				>
					{{ textOrder }}
					<img src="@/assets/img/icon/arrow-drop-down.svg" alt="" />
				</button>
				<ul
					class="dropdown status"
					v-if="isShowDropdownStatus"
					v-click-outside="outsideOrder"
				>
					<li
						v-for="item in status"
						:key="item.key"
						@click="handleStatus(item)"
						:class="{
							active: item.key === proposalRequest.status,
						}"
					>
						{{ item.name }}
					</li>
				</ul>
			</div>
		</div>
		<div class="main" v-if="filteredLists.length > 0">
			<div
				v-for="proposal in filteredLists"
				:key="proposal.id"
				class="proposal-item"
			>
				<ActivityItem
					:data="proposal"
					:watchList="watchList"
					:curTab="curTab"
					:currentPage="proposalRequest.pageIndex"
					:status="proposalRequest.status"
					@update-watchlist="updateWatchlist"
				/>
			</div>
		</div>
		<div class="no-proposal" v-else>
			<div class="no-proposal-detail">
				<img :src="'/img/icon/file-missing.svg'" alt="" />
				<div class="no-title">There’s no proposal yet</div>
				<div class="no-content">
					Give us your ideas to make the Dvision World better
				</div>
				<button class="create-proposal" @click="createProposal()">
					Write your proposal
				</button>
			</div>
		</div>
		<Pagination
			v-if="proposalCount"
			align="right"
			:totalRows="proposalCount"
			:perPage="proposalRequest.pageSize"
			:currentPage="proposalRequest.pageIndex"
			@changePage="changePage"
		/>
	</div>
</template>

<script>
import { getMyProposal, getMyVotes } from '@/services/proposals.js'
import {
	removeProposalFromWatchlist,
	addProposalToWatchlist,
	getWatchlist,
} from '@/services/watchlist.js'
import ActivityItem from './ActivityItem.vue'
import Pagination from '@/components/Base/Pagination.vue'
import vClickOutside from 'click-outside-vue3'
export default {
	name: 'Activity',
	components: {
		ActivityItem,
		Pagination,
	},
	data() {
		return {
			curTab: 'Proposals',
			filteredLists: [],
			proposalRequest: {
				pageIndex: 1,
				pageSize: 12,
				userId: this.$store.state.userInfo.wallet_addr,
				status: undefined,
			},
			watchList: [],
			proposalCount: 0,
			isShowDropdownStatus: false,
			textOrder: 'All Status',
			status: [
				{ key: undefined, name: 'All Status' },
				{ key: 'inprogress', name: 'Voting in Progress' },
				{ key: 'ended', name: 'End of Voting' },
				{
					key: 'proceeding',
					name: 'Proceeding',
				},
				{ key: 'complete', name: 'Complete' },
				{ key: 'rejected', name: 'Rejected' },
			],
		}
	},
	directives: {
		clickOutside: vClickOutside.directive,
	},
	created() {
		this.curTab = this.$route.query.curTab
			? this.$route.query.curTab
			: 'Proposals'

		this.proposalRequest.pageIndex = this.$route.query.page
			? this.$route.query.page
			: 1

		if (this.$route.query.status) {
			this.proposalRequest.status = this.$route.query.status
			switch (this.proposalRequest.status) {
				case 'inprogress':
					this.textOrder = 'Voting in Progress'
					break
				case 'ended':
					this.textOrder = 'End of Voting'
					break
				case 'proceeding':
					this.textOrder = 'Proceeding'
					break
				case 'complete':
					this.textOrder = 'Complete'
					break
				case 'rejected':
					this.textOrder = 'Rejected'
					break
				default:
					break
			}
		}
		this.fetchAllWatchlist()
		switch (this.curTab) {
			case 'Proposals':
				this.fetchMyProposals()
				break
			case 'Watchlist':
				this.fetchWatchlist()
				break
			case 'MyVotes':
				this.fetchMyVotes()
				break
			default:
				break
		}
	},
	beforeMount() {
		if (!this.$store.state.userInfo.wallet_addr) {
			this.$router.push({
				name: 'Catalyst',
			})
		}
	},
	watch: {
		'$route.query'() {
			if (this.$route.query.page) {
				this.proposalRequest.pageIndex = this.$route.query.page
			}
			this.proposalCount = 0
			switch (this.curTab) {
				case 'Proposals':
					this.fetchMyProposals()
					break
				case 'Watchlist':
					this.fetchWatchlist()
					break
				case 'MyVotes':
					this.fetchMyVotes()
					break
				default:
					break
			}
		},
	},
	computed: {},
	methods: {
		outsideOrder() {
			this.isShowDropdownStatus = false
		},
		async handleStatus(item) {
			this.isShowDropdownStatus = false
			this.textOrder = item.name
			this.proposalRequest.status = item.key
			this.proposalRequest.pageIndex = 1
			this.proposalCount = 0
			this.$router.push({
				name: 'Activity',
			})
			switch (this.curTab) {
				case 'Proposals':
					this.fetchMyProposals()
					break
				case 'Watchlist':
					this.fetchWatchlist()
					break
				case 'MyVotes':
					this.fetchMyVotes()
					break
				default:
					break
			}
		},
		changeTab(tab, page) {
			this.$router.push({
				name: 'Activity',
				curTab: tab,
			})
			this.curTab = tab
			this.proposalRequest.pageIndex = page || 1
			this.proposalCount = 0
			switch (this.curTab) {
				case 'Proposals':
					this.fetchMyProposals()
					break
				case 'Watchlist':
					this.fetchWatchlist()
					break
				case 'MyVotes':
					this.fetchMyVotes()
					break
				default:
					break
			}
		},
		async fetchMyProposals() {
			this.filteredLists = []
			let payload = {
				user: this.$store.state.userInfo.wallet_addr,
				pageIndex: this.proposalRequest.pageIndex,
				pageSize: this.proposalRequest.pageSize,
				status: this.proposalRequest.status,
			}
			this.mxShowLoading('inf')
			await getMyProposal(payload)
				.then((res) => {
					if (res.count) {
						this.filteredLists = []
						res.listMyProposals.forEach((obj) => {
							console.log(obj);
							this.filteredLists.push({
								id: obj.snapshotId,
								proposals_snapshotId: obj.snapshotId,
								proposals_title: obj.title,
								proposals_type: obj.type,
								proposals_status: obj.status,
								proposals_snapshotSpace: obj.snapshotSpace,
								proposals_snapshotNetwork: obj.snapshotNetwork,
								proposals_snapshotProposal: obj.snapshotProposal,
								proposals_strategies: obj.strategies
							})
						})
						this.proposalCount = res.count
					}
					this.mxCloseLoading()
				})
				.catch((err) => {
					console.log(err)
					this.mxCloseLoading()
				})
		},
		async fetchAllWatchlist() {
			let payload = {
				userId: this.$store.state.userInfo.wallet_addr,
				pageIndex: 1,
				pageSize: 9999,
				status: undefined,
			}
			await getWatchlist(payload).then((res) => {
				if (res.count !== 0) {
					this.watchList = res.listProposals
				}
			})
		},
		async fetchWatchlist() {
			this.mxShowLoading('inf')
			this.filteredLists = []
			await getWatchlist(this.proposalRequest)
				.then((res) => {
					this.proposalCount = res.count
					this.filteredLists = res.listProposals
					if (
						(res.listProposals.length == 0) &
						(this.proposalRequest.pageIndex > 1)
					) {
						this.proposalRequest.pageIndex -= 1
						this.$router.push({
							query: {
								page: this.proposalRequest.pageIndex,
							},
						})
					}
					this.mxCloseLoading()
				})
				.catch((err) => {
					console.log(err)
					this.mxCloseLoading()
				})
		},
		async fetchMyVotes() {
			// this.filteredLists = []
			this.mxShowLoading('inf')
			this.filteredLists = []
			await getMyVotes(this.proposalRequest)
				.then((res) => {
					this.filteredLists = res.listMyProposals
					this.proposalCount = res.count
					this.mxCloseLoading()
				})
				.catch((err) => {
					console.log(err)
					this.mxCloseLoading()
				})
		},
		async updateWatchlist(e) {
			if (
				this.watchList
					.map((item) => item.proposals_snapshotId)
					.indexOf(e.proposals_snapshotId) != -1
			) {
				await removeProposalFromWatchlist(
					e.proposals_snapshotId,
					this.$store.state.userInfo.wallet_addr
				)
					.then((res) => {
						if (this.watchList.length === 1) {
							this.watchList = []
						} else {
							this.fetchAllWatchlist()
						}
						if (this.curTab === 'Watchlist') this.fetchWatchlist()
					})
					.catch((err) => {
						console.log(err)
					})
			} else {
				let payload = {
					snapshotId: e.proposals_snapshotId,
					userId: this.$store.state.userInfo.wallet_addr,
				}
				addProposalToWatchlist(payload).then((res) => {
					this.fetchAllWatchlist()
					if (this.curTab === 'Watchlist') this.fetchWatchlist()
				})
			}
		},
		createProposal() {
			this.$router.push({ path: 'submit' })
		},
		changePage(page) {
			let query = undefined
			if (page == 1) {
				query = {
					curTab: this.curTab,
				}
			} else {
				query = {
					curTab: this.curTab,
					page: page,
				}
			}
			this.$router.push({
				name: 'Activity',
				query,
			})
			this.proposalRequest.pageIndex = page
			switch (this.curTab) {
				case 'Proposals':
					this.fetchMyProposals()
					break
				case 'Watchlist':
					this.fetchWatchlist()
					break
				case 'MyVotes':
					this.fetchMyVotes()
					break
				default:
					break
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.activity {
	font-family: 'Montserrat';
	font-style: normal;
	grid-column: span 4;
	padding-right: gREm(20);
	& * {
		color: black;
	}
	.header {
		display: flex;
		justify-content: space-between;
		.tab-res {
			background: rgba(108, 56, 239, 0.15);
			border-radius: 4px;
			max-width: gREm(372);
		}
		& .tab-list {
			display: flex;
			padding: gREm(4);
			position: relative;
			overflow: auto;
			&::-webkit-scrollbar {
				height: 0px;
			}
			& .tab-item {
				cursor: pointer;
				font-size: gREm(16);
				line-height: gREm(24);
				padding: gREm(6) gREm(15);
				white-space: nowrap;
				font-weight: 600;
				font-size: 16px;
				line-height: 150%;
				color: #6c38ef;

				&.active {
					background: #6c38ef;
					border-radius: 4px;
					font-weight: 700;
					color: #ffffff;
				}
			}
		}
		.status-box {
			.status {
				position: relative;
				// margin: 0 6px;
				height: 44px;
				background: #fff;
				border: 1px solid #efeeef;
				border-radius: 4px;
				font-family: 'Montserrat';
				width: 210px;
				font-size: gREm(16);
				line-height: gREm(24);
				padding: gREm(7) gREm(16);
			}
			ul.dropdown {
				top: 34px;
				position: absolute;
				z-index: 100;
				height: auto;
				background: #fff;
				border: 1px solid #efeeef;
				border-radius: 4px;
				list-style-type: none;
				&.status {
					left: 0px;
					width: 210px;
					top: 44px;
				}
				li {
					padding: 5.5px 14px;
					font-size: 14px;
					color: #737373;
					&:hover {
						cursor: pointer;
					}
					&.active {
						background: #f9fafb;
						font-weight: 600;
						color: #201360;
					}
				}
			}
		}
	}
	.main {
		margin-top: gREm(40);
		margin-bottom: gREm(32);
		display: grid;
		grid-template-columns: 33% 33% 33%;
		gap: gREm(20);
		width: 100%;
		height: auto;

		.proposal-item {
			background: #ffffff;
			border: 1px solid #efeeef;

			border-radius: 8px;
			grid-column: span 1;

			&:hover {
				border: 1px solid #6c38ef;
				box-shadow: 0px 8px 8px rgba(48, 24, 108, 0.2);
				transform: translateY(gREm(-5));
				transition: 0.2s;
				cursor: pointer;
			}
		}
	}
	.no-proposal {
		display: grid;
		justify-content: center;
		margin: gREm(66) auto;
		padding: 24px;
		text-align: center;
		background: #ffffff;
		border: 1px solid #eef2ff;
		border-radius: 8px;
		width: 500px;
		.no-proposal-detail {
			text-align: center;
			img {
				width: 170px;
				height: auto;
			}
			.no-title {
				font-weight: 700;
				font-size: 18px;
				line-height: 150%;
				color: #17141a;
			}

			.no-content {
				font-weight: 400;
				font-size: 14px;
				line-height: 150%;
				color: #747276;
				margin-bottom: gREm(16);
			}
			.create-proposal {
				background: #6c38ef;
				border-radius: 4px;
				font-weight: 600;
				font-size: 16px;
				line-height: 24px;
				font-family: 'Montserrat';
				font-style: normal;
				color: #f9fafb;
				padding: 8px 24px;
			}
		}
	}
}
@include media-max($media_large) {
	// 1200
	.activity {
		.main {
			.proposal-item {
				grid-column: span 1;
			}
		}
	}
}

@include media-max($media_medium) {
	// 1024
	.activity {
		.main {
			grid-template-columns: 50% 50%;
			.proposal-item {
				grid-column: span 1;
			}
		}
	}
}

@include media-max($media_small) {
	// 768
	.activity {
		padding-right: 0;
		.header {
			display: block;
			.status {
				margin-top: gREm(12);
			}
		}
		.main {
			display: block;
			.proposal-item {
				margin-bottom: gREm(16);
			}
		}
		.no-proposal {
			width: 100%;
		}
	}
}
</style>
